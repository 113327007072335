:root {
  --primary-color: #000d3c;
  --bg-color: #d1e6ef;
  --button-gredient: linear-gradient(
    90deg,
    rgba(20, 78, 173, 1) 5%,
    rgba(0, 13, 60, 1) 54%
  );
  --text-gredient: linear-gradient(
    90deg,
    rgba(189, 47, 73, 1) 0%,
    rgba(119, 13, 115, 1) 34%,
    rgba(0, 35, 119, 1) 75%
  );
}

.footer-wrapper {
  background-color: #08143c;
  color: white;
  p,
  h4 {
    padding: 0 !important;
    margin: 0 !important;
  }
  .footer__content {
    h4 {
      padding: 1.5rem 0 !important;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    > div {
      flex: 1;
      margin-right: 2rem;
    }
    .contact {
      p {
        padding: 0.5rem 0 !important;
        color: gray;
        font-size: 14px;
        // flex: 1;
        // width: 20%;
      }
      .location {
        align-items: center;
        gap: 0.5rem;
        p {
          flex: 1;
        }
      }
      .phoneNumber {
        align-items: center;
        gap: 0.2rem;
        padding: 0.3rem 0;
        a{
          color: gray;
          text-decoration: none;
          padding-left: 10px;
        }
        
      }
      .envelope {
        align-items: center;
        gap: 1rem;
        padding: 0.3rem 0;
        a {
          color: #e5eb0b;
          font-size: 14px;
        }
      }
    }

    .quick-links, .popular-location {
      flex-direction: column;
      gap: 1rem;
      margin-left: 2rem;
      a {
        font-size: 14px;
        color: #e5eb0b;
      }
    }
    .followUs-icons {
      gap: 2rem;

      svg {
        color: #e5eb0b;
        cursor: pointer;
        &:hover {
          transform: scale(1.5);
          transition: 0.5s;
        }
      }
    }
    @media screen and (max-width: 992px) {
      flex-direction: column;
      .quick-links, .popular-location{
        margin-left: 0;
      }
    }
   
  }
  .footer-bottom {
    padding: 1rem 0 !important;
    a {
      color: #e5eb0b;
      text-decoration: none;
      font-size: 14px;
    }
  }
}
