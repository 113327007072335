:root {
  --primary-color: #000d3c;
  --bg-color: #d1e6ef;
  --button-gredient: linear-gradient(
    90deg,
    rgba(20, 78, 173, 1) 5%,
    rgba(0, 13, 60, 1) 54%
  );
  --hover-button: linear-gradient(
    90deg,
    rgba(0, 13, 60, 1) 5%,
    rgba(20, 78, 173, 1) 54%
  );
  --text-gredient: linear-gradient(
    90deg,
    rgba(189, 47, 73, 1) 0%,
    rgba(119, 13, 115, 1) 34%,
    rgba(0, 35, 119, 1) 75%
  );
}
.header {
  background-color: red !important ;
  .navbar {
    background-color: var(--bg-color) !important;
    .container {
      max-width: 90% !important;
      .navbar-brand {
        width: 15%;
        object-fit: contain;
        @media screen and (max-width: 991px) {
          width: 45%;
        }
      }
      .navbar-collapse {
        flex-grow: 0 !important;
        @media screen and (max-width: 991px) {
          gap: 2rem;
        }
        .nav-link,
        a {
          color: var(--primary-color);
          font-weight: 500;
          margin: 0 0.5rem;
          font-size: 14px;
          padding: 0 0.5rem !important;
          text-decoration: none;
          @media screen and (max-width: 1024px ) {
            margin: 0 !important;
          }
        }
        .dropdown-item {
          padding: 0.5rem 0.5rem !important;
        }
        .active {
          // color: #bd2f49 !important;
          font-weight: 600 !important;
        }
        @media screen and (min-width: 991px) {
          .dropdown {
            display: none;
          }
          .hover-dropdown-head {
            display: none;
          }
        }
        @media screen and (max-width: 991px) {
          .hover-dropdown-head {
            display: none;
          }
          .navbar-brand a {
            width: 50% !important;
          }
          .dropdown-menu {
            width: 100%;

            border: none;
            padding: 0;
            background-color: var(--bg-color);
            a {
              margin: 0;
              color: white;
              background-color: var(--primary-color);
              margin: 5px 0;
              transition: 0.5s;
              &:hover {
                transform: scale(1.1);
                box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
                  rgba(17, 17, 26, 0.1) 0px 8px 24px,
                  rgba(17, 17, 26, 0.1) 0px 16px 48px;
              }
            }
          }
        }
        @media screen and (min-width: 992px) and (max-width: 3024px) {
          .hover-dropdown-head {
            display: block;
            position: relative;
            font-weight: 500;
            font-size: 14px;
            padding: 0 0.5rem;
            cursor: pointer;
          }

          .hover-dropdown {
            visibility: hidden;
            position: absolute;
            height: auto;
            background: white;
            z-index: 10;
            width: 350px;
            padding: 15px;
            top: 40px;
            right: -200px;
            gap: 0.5rem;
            display: flex;
            flex-direction: column;
            border-radius: 5px;
            color: var(--primary-color);
            transform: translateY(20px);
            transition: all 0.3s ease-out;
            opacity: 0;
            line-height: 2;

            a {
              margin: 0;
              padding: 0rem 0.5rem !important;
            }
          }
          .hover-dropdown-link {
            color: black;
            position: relative;
            font-weight: 600;
            line-height: 10px !important;
            &::after {
              content: "";
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 0%;
              height: 4px;
              background-color: #f1cd13;
              border-radius: 3px;
              transition: all 0.3s ease;
            }
            &:hover::after {
              width: 100%;
            }
          }
          .hover-dropdown-head:hover .hover-dropdown {
            visibility: visible;
            transform: translateY(10px);
            opacity: 1;
          }
        }
        .enquiery {
          background: var(--button-gredient);
          color: white;
          padding: 0.5rem !important;
          border-radius: 5px;
          transition: 0.5s;
          &:hover{
            background: var(--hover-button);
          }
          @media screen and (max-width: 991px) {
            a {
              padding: 0;
              margin: 0;
            }
            padding: 0.1rem 0.5rem !important;
          }
        }
      }
    }
  }
}
.header2 {
  box-shadow: 0 2px 4px rgba(2, 15, 29, 0.356);
  transform: translateY(10);
  transition: transform 2s ease;
  animation: myanimation 1s;
  @keyframes myanimation {
    0% {
      background-color: transparent;
      transform:translateY(-10px);
      
    }

    35% {
      transform:translateY(-20px);
      visibility: hidden;
     
    }
    50%{
      transform: translateY(-10px);
      
    }
    100% {
      transform: translateY(0);
      visibility: visible;
    }
  }
  width: 100%;
  z-index: 20;
  position: fixed;
  top: 0;
  .navbar {
    background-color: white !important;
    .container {
      max-width: 90% !important;
      .navbar-brand {
        width: 15%;
        object-fit: contain;
        @media screen and (max-width: 991px) {
          width: 45%;
        }
      }
      .navbar-collapse {
        flex-grow: 0 !important;
        @media screen and (max-width: 991px) {
          gap: 2rem;
        }
        .nav-link,
        a {
          color: var(--primary-color);
          font-weight: 500;
          margin: 0 0.5rem;
          font-size: 14px;
          padding: 0 0.5rem !important;
          text-decoration: none;
        }
        .dropdown-item {
          padding: 0.5rem 0.5rem !important;
        }
        .active {
          // color: #bd2f49 !important;
          font-weight: 600 !important;
        }
        @media screen and (min-width: 991px) {
          .dropdown {
            display: none;
          }
          .hover-dropdown-head {
            display: none;
          }
        }
        @media screen and (max-width: 991px) {
          .hover-dropdown-head {
            display: none;
          }
          .navbar-brand a {
            width: 50% !important;
          }
          .dropdown-menu {
            width: 100%;

            border: none;
            padding: 0;
            background-color: var(--bg-color);
            a {
              margin: 0;
              color: white;
              background-color: var(--primary-color);
              margin: 5px 0;
              transition: 0.5s;
              &:hover {
                transform: scale(1.1);
                box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
                  rgba(17, 17, 26, 0.1) 0px 8px 24px,
                  rgba(17, 17, 26, 0.1) 0px 16px 48px;
              }
            }
          }
        }
        @media screen and (min-width: 992px) and (max-width: 3024px) {
          .hover-dropdown-head {
            display: block;
            position: relative;
            font-weight: 500;
            font-size: 14px;
            padding: 0 0.5rem;
            cursor: pointer;
          }
          .hover-dropdown {
            visibility: hidden;
            position: absolute;
            height: auto;
            background: white;
            z-index: 10;
            width: 350px;
            top: 40px;
            right: -200px;
            gap: 0.5rem;
            display: flex;
            flex-direction: column;
            box-shadow: -4px 3px 62px -3px rgba(0, 0, 0, 0.28);
            border-radius: 5px;
            padding: 15px;
            div{
              line-height: 2;
              a {
                margin: 0;
                padding: 0rem 0.5rem !important;
              }
            }
            
            transform: translateY(20px);
            opacity: 0;
            transition: all 0.3s ease;
          }
          .hover-dropdown-link {
            color: black;
            font-weight: 600;
            position: relative;
            &::after {
              content: "";
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 0%;
              height: 4px;
              background-color: #f1cd13;
              border-radius: 3px;
              transition: all 0.3s ease;
            }
            &:hover::after {
              width: 100%;
            }
          }
        }
        .hover-dropdown-head:hover .hover-dropdown {
          visibility: visible;
          transform: translateY(10px);
          opacity: 1;
        }
        .enquiery {
          background: var(--button-gredient);
          color: white;
          padding: 0.5rem !important;
          border-radius: 5px;
          &:hover{
            background: var(--hover-button);
          }
          @media screen and (max-width: 991px) {
            a {
              padding: 0;
              margin: 0;
            }
            padding: 0.1rem 0.5rem !important;
          }
        }
      }
    }
  }
}
