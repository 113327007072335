:root {
  --primary-color: #000d3c;
  --bg-color: #d1e6ef;
  --button-gredient: linear-gradient(
    90deg,
    rgba(20, 78, 173, 1) 5%,
    rgba(0, 13, 60, 1) 54%
  );
  --text-gredient: linear-gradient(
    90deg,
    rgba(189, 47, 73, 1) 0%,
    rgba(119, 13, 115, 1) 34%,
    rgba(0, 35, 119, 1) 75%
  );
}

.filter2-wrapper {
  .filter2 {
    &__searchSection {
      .form {
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        border-radius: 3px;
        margin: auto;
        > select {
          flex: 1;
        }
        select {
          border: 1px solid rgba(128, 128, 128, 0.456);
          border-radius: 5px;
          padding: 0.2rem 1rem;
          color: gray;
          outline: 1px solid transparent;
          cursor: pointer;
          &:focus {
            background-color: #f2f2f2;
            outline: 1px solid var(--primary-color);
            border: 1px solid transparent;
            box-shadow: 1px 1px 1px 1px var(--primary-color);            
          }
          &:active{
            background-color: #e2e2e2;
          }
        }
        .form-select {
          border: none;
          color: var(--primary-color) !important;
          font-size: 12px;
        }

        .submit {
          background: var(--button-gredient);
          color: white;
          padding: 0.5rem 1rem !important;
          border-radius: 5px;
          border: none;
          outline: none;
          font-size: 12px;
          &:hover{
            background: var(--hover-button);
          }
        }
        @media screen and (max-width: 992px) {
          flex-direction: column;
          width: fit-content;
          align-items: inherit !important;
          margin: auto;
        }
      }
    }
    &__mainSection-wrapper {
      background-color: #f7f7f7;
      p {
        margin: 0;
      }
      .mainSection {
        gap: 2rem;
        &__left {
          flex: 2;
          .result__title {
            p {
              font-weight: 600;
            }
          }
          .resultSort {
            justify-content: space-between;
            align-items: center;
            // flex:1;
            &__right {
              flex: 1;
              gap: 1rem;
              align-items: center;
              justify-content: end;              
              select {
                border: 2px solid rgba(128, 128, 128, 0.456);
                border-radius: 5px;
                padding: 0.1rem 1rem;
                color: gray;
                cursor: pointer;
                &:focus {
                  // box-shadow: 1px 1px 10px 1px var(--primary-color);
                  outline: 1px solid var(--primary-color);
                  border: 2px solid transparent;
                  background-color: #f2f2f2;
                }
                &:active{
                  background-color: #e2e2e2;
                }
              }
              .icons {
                gap: 10px;
                cursor: pointer;
                svg {
                  padding: 5px;
                  width: 2rem;
                  height: 2rem;
                  border: 1px solid rgba(128, 128, 128, 0.456);
                  color: rgba(128, 128, 128, 0.456);
                  border-radius: 5px;
                  &:hover,
                  &:focus {
                    border: 1px solid var(--primary-color);
                    color: var(--primary-color);
                    transition: 0.5s;
                  }
                }
                @media screen and (max-width: 992px) {
                  display: none !important;
                }
              }
            }
            &__right {
              flex: 1;
            }
          }
          .card {
            grid-template-columns: auto auto;
            gap: 2rem;
            border: none;
            background-color: transparent;
            a{
              text-decoration: none;
              color: inherit;
            
            .imgDiv {
              border-bottom: 2px solid var(--primary-color);
              border-radius: 5px;
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              background-color: white;
              max-height: 350px;
              min-height: 350px;
              &__top {
                height: 200px;
                position: relative;
                button {
                  position: absolute;
                  top: 0;
                  right: 5px;
                  border: none;
                  outline: none;
                  //padding: 10px;
                  height: 2rem;
                  width: 2rem;
                  color: #fff;
                  background-color: var(--primary-color);
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                  &:hover {
                    transition: 0.5s;
                    background-color: #fff;
                    color: var(--primary-color);
                  }
                }
                img {
                  object-fit: cover;
                  // border-top-right-radius: 5px;
                  // border-top-left-radius: 5px;
                }
              }
              &__bottom {
                padding: 1rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                p:nth-child(1) {
                  font-weight: 600;
                  color: var(--primary-color);
                  line-height: 1.5em;
                  height: 3em;
                  white-space: wrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                small {
                  font-size: 12px;
                }
              }
              }
            }
            @media screen and (max-width: 600px) {
              grid-template-columns: 100%;
            }
            @media screen and (min-width: 601px) and (max-width: 992px) {
              grid-template-columns: auto auto;
            }
          }
          .card2 {
            grid-template-columns: auto auto auto;
            gap: 2rem;
            border: none;
            background-color: transparent;

            .imgDiv {
              border-bottom: 2px solid var(--primary-color);
              border-radius: 5px;
              box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
              background-color: white;
              &__top {
                height: 200px;
                position: relative;
                button {
                  position: absolute;
                  top: 0;
                  right: 5px;
                  border: none;
                  outline: none;
                  //padding: 10px;
                  height: 2rem;
                  width: 2rem;
                  color: #fff;
                  background-color: var(--primary-color);
                  border-bottom-left-radius: 5px;
                  border-bottom-right-radius: 5px;
                  &:hover {
                    transition: 0.5s;
                    background-color: #fff;
                    color: var(--primary-color);
                  }
                }
                img {
                  border-top-right-radius: 5px;
                  border-top-left-radius: 5px;
                }
              }
              &__bottom {
                padding: 1rem;
                display: flex;
                flex-direction: column;
                gap: 1rem;
                p:nth-child(1) {
                  font-weight: 600;
                  color: var(--primary-color);
                }
                small {
                  font-size: 12px;
                }
              }
            }
            @media screen and (max-width: 600px) {
              grid-template-columns: 100%;
            }
            @media screen and (min-width: 601px) and (max-width: 992px) {
              grid-template-columns: auto auto;
            }
          }
          // .card3 {
          //   grid-template-columns: auto;
          //   gap: 2rem;
          //   border: none;
          //   background-color: transparent;

          //   .imgDiv {
          //     display: flex;
          //     border-bottom: 2px solid var(--primary-color);
          //     border-radius: 5px;
          //     box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
          //     background-color: white;
          //     &__top {
          //       height: 200px;
          //       position: relative;
          //       button {
          //         position: absolute;
          //         top: 0;
          //         right: 5px;
          //         border: none;
          //         outline: none;
          //         //padding: 10px;
          //         height: 2rem;
          //         width: 2rem;
          //         color: rgb(202, 39, 39);
          //         background-color: pink;
          //         border-bottom-left-radius: 5px;
          //         border-bottom-right-radius: 5px;
          //         &:hover {
          //           transition: 0.5s;
          //           background-color: rgb(202, 39, 39);
          //           color: pink;
          //         }
          //       }
          //       img {
          //         border-top-right-radius: 5px;
          //         border-top-left-radius: 5px;
          //       }
          //     }
          //     &__bottom {
          //       padding: 1rem;
          //       display: flex;
          //       flex-direction: column;
          //       gap: 1rem;
          //       p:nth-child(1) {
          //         font-weight: 600;
          //         color: var(--primary-color);
          //       }
          //       small {
          //         font-size: 12px;
          //       }
          //     }
          //   }
          //   @media screen and (max-width: 600px) {
          //     grid-template-columns: 100%;
          //   }
          //   @media screen and (min-width: 601px) and (max-width: 992px) {
          //     grid-template-columns: auto auto;
          //   }
          // }

          .pagination{
            .page-link{
              color: var(--primary-color);
            }
            .active>.page-link{
              background: var(--primary-color);
              color: #fff;
              border: 2px solid var(--primary-color);
            }
            @media screen and (max-width: 992px) {
              transform: scale(0.8);
            }
          }
          @media screen and (max-width: 992px) {
            flex: 1;
          }
        }
        &__right {
          flex: 1;
        }
        @media screen and (max-width: 992px) {
          flex-direction: column;
        }
        .filterForm {
          background-color: white;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          padding: 1rem;
          border-radius: 5px;
          label {
            display: block;
          }
          input,
          select {
            width: 100%;
            // border: 1px solid rgba(128, 128, 128, 0.456);
            // border-radius: 5px;
            padding: 0.2rem 1rem;
            color: gray;
            cursor: pointer;
            outline: 1px solid transparent;
            // box-shadow: 1px 1px 1px  transparent;
            transition: 0.5s;
            &:focus {
              // outline: 1px solid var(--primary-color);
              // border: 1px solid transparent;
              // background-color: #f2f2f2;
              // box-shadow: 1px 1px 1px  var(--primary-color);
            }
            &:active{
            background-color: #e2e2e2;
            }
          }
          .price {
            gap: 1rem;
            @media screen and (max-width: 992px) {
              justify-content: space-between;
            }
          }
          .filterButton {
            button {
              background: var(--button-gredient);
              color: white;
              padding: 0.5rem 1rem !important;
              border-radius: 5px;
              border: none;
              outline: none;
              font-size: 12px;
            }
          }
        }
        .featuredListing,
        .recentSearch {
          background-color: white;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          padding: 1rem;
          border-radius: 5px;
          &__card {
            gap: 1rem;
            align-items: center;
            margin: 2rem 0 !important ;
            .imgDiv {
              height: 100px;
              max-width: 100px;
              min-width: 100px;
              object-fit: contain;
            }
            .content {
              p {
                font-weight: 500;
                color: black;
              }
            }
          }
        }
      }
    }
  }
}
