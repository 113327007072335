:root {
  --primary-color: #000d3c;
  --bg-color: #d1e6ef;
  --button-gredient: linear-gradient(
    90deg,
    rgba(20, 78, 173, 1) 5%,
    rgba(0, 13, 60, 1) 54%
  );
  --text-gredient: linear-gradient(
    90deg,
    rgba(189, 47, 73, 1) 0%,
    rgba(119, 13, 115, 1) 34%,
    rgba(0, 35, 119, 1) 75%
  );
}
.innerpage-wrapper {
  background-color: white;
  .innerPage {
    &__section1 {
      gap: 1rem;
      h6,
      h4 {
        margin: 0 !important;
        text-transform: capitalize !important;
      }
      .section1__left {
        flex: 2;
        padding: 1rem;

        .thumbs-wrapper {
          margin: 0 !important;
          border-radius: 5px;
          .thumbs {
            padding: 0 !important;
            margin-top: 1rem;
            li {
              margin-right: 1rem;
            }
          }
        }
        .social-icons {
          button {
            padding: 0.5rem 1rem;
            border-radius: 5px;
            display: flex;
            align-items: center;
            gap: 10px;
            border: none;
            outline: none;
            font-weight: 600;
            color: white;
            transition: all 0.5s;
            cursor: pointer;
            &:nth-child(1) {
              background: #097ceb;
              border: 1px solid #097ceb;
              &:hover {
                background: white;
                color: #097ceb;
              }
            }
            &:nth-child(2) {
              background: #1da1f2;
              border: 1px solid #1da1f2;
              &:hover {
                background: white;
                color: #1da1f2;
              }
            }
            &:nth-child(3) {
              background: #b7081b;
              border: 1px solid #b7081b;
              &:hover {
                background: white;
                color: #b7081b;
              }
            }
            &:nth-child(4) {
              background: #ea4335;
              border: 1px solid #ea4335;
              &:hover {
                background: white;
                color: #ea4335;
              }
            }
          }
          @media screen and (max-width: 600px) {
            flex-direction: column;
            gap: 1rem;
            align-items: inherit;
          }
        }
      }
      .section1__right {
        flex: 1;
        // background-color: aliceblue;
        padding: 1rem;
        border-radius: 5px;
        // position: fixed;
        span {
          color: red;
          font-weight: bold;
        }
        .innerPage__form {
          .error-message {
            color: #ff0000;
            font-size: 13px;
            margin-top: 10px;
          }
          p {
            font-size: 12px;
            font-weight: 600;
            color: var(--primary-color);
          }
          button {
            width: 100%;
            outline: none;
            border: none;
            padding: 0.5rem 1rem;
            border: 1px solid rgba(128, 128, 128, 0.253);
            border-radius: 5px;
            color: #fff;
            font-weight: 600;
            letter-spacing: 2px;
            background: var(--button-gredient);
            &:hover {
              background: var(--hover-button);
            }
          }
          .form-control {
            background: transparent;
            border: none;
            margin: 0;
            padding: 0;
            label {
              display: block;
              padding: 0.5rem 0;
              color: black;
              font-weight: 600;
              letter-spacing: 2px;
            }
            input {
              width: 100%;
              outline: none;
              border: none;
              padding: 0.5rem 1rem;
              border: 1px solid rgba(128, 128, 128, 0.234);
              border-radius: 5px;
            }
            textarea {
              width: 100%;
              height: 7rem;
              resize: none;
              outline: none;
              border: none;
              padding: 0.5rem 1rem;
              border: 1px solid rgba(128, 128, 128, 0.253);
              border-radius: 5px;
              // background: transparent;
            }
          }
        }
        .gmap-wrapper {
          iframe {
            width: 100%;
            height: 400px;
            margin-top: 32px;
          }
        }
      }
      @media screen and (max-width: 992px) {
        flex-direction: column;
      }
    }
    &__section2 {
      padding: 2rem 0;
      .title {
        h5 {
          color: var(--primary-color);
          font-weight: 600;
        }
        p {
          font-size: 14px;
          font-weight: 600;
        }
      }
      .details {
        grid-template-columns: repeat(2, 1fr);
        align-items: start;
        &__title {
          ul {
            padding: 0;
            li {
              margin: 1rem 0;
              // color: black;
              transition: 0.3s all ease;
              text-transform: capitalize;
              strong {
                font-weight: 500;
                border: none;
                font-size: 16px;
              }
              &:hover {
                // transform: translateX(1rem);
                color: var(--primary-color);
              }
            }
          }
          .commercial {
            li {
              margin: unset;
              transition: 0.3s all ease;
              text-transform: capitalize;
            }
          }
          strong {
            border-bottom: 1px solid black;
            margin-bottom: 0.5rem;
            display: inline-block;
            color: black;
            font-size: 18px;
          }
          svg {
            display: inline-block;
            font-size: 1.5rem;
            margin-right: 1rem;
          }
        }
        @media screen and (max-width: 992px) {
          grid-template-columns: 100%;
        }
      }
    }
    .pagination {
      .page-link {
        color: var(--primary-color);
      }
      .active > .page-link {
        background: var(--primary-color);
        color: #fff;
        border: 2px solid var(--primary-color);
      }
    }
    .carousel .control-next.control-arrow:before {
      border-left: 8px solid #000 !important;
    }

    .carousel .control-prev.control-arrow:before {
      border-right: 8px solid #000 !important;
    }

    .carousel .slide img {
      height: 600px;
      object-fit: contain;
      background-color: #d1e6ef;
    }

    .carousel .thumb img {
      height: 45px;
    }
  }
}
