/* universel selector */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  text-decoration: none;
}
li {
  list-style-type: none;
}

/* Roots colors */
  :root {
    --primary-color: #000d3c;
    --bg-color: #d1e6ef;
    --button-gredient: linear-gradient(
      90deg,
      rgba(20, 78, 173, 1) 5%,
      rgba(0, 13, 60, 1) 54%
    );
    --hover-button: linear-gradient(
      90deg,
      rgba(0, 13, 60, 1) 5%,
      rgba(20, 78, 173, 1) 54%
    );
    --text-gredient: linear-gradient(
      90deg,
      rgba(189, 47, 73, 1) 0%,
      rgba(119, 13, 115, 1) 34%,
      rgba(0, 35, 119, 1) 75%
    );
  }

  body{
    position: relative;
  }
  .whatsapp{
    position: fixed;
    bottom: 20%;
    right: 2%;
    z-index: 10;
    width: 60px;
    height: 60px;
    background-color: limegreen;
    font-size: 35px;
    text-align: center;
    border-radius: 30px;
    border:2px solid;

    @media screen and (max-width: 2560px) and (min-width: 1024px) {
      right: 0.5%;
    }
  }
  .whatsapp a svg{
      color: white;
  }

img {
  width: 100%;
  height: 100%;
}

li {
  list-style-type: none;
}

.container {
  max-width: 90% !important;
  margin: 0 auto;
}

.form-control {
  margin-bottom: 20px;
}

.error {
  color: red;
}


.ant-select-arrow{
  color: black !important;
  margin-top: -5px !important;
  font-size: 10px !important; 
}

.ant-select-clear{
  margin-top: -7px !important;
}