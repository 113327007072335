:root {
    --primary-color: #000d3c;
    --bg-color: #d1e6ef;
    --button-gredient: linear-gradient(
      90deg,
      rgba(20, 78, 173, 1) 5%,
      rgba(0, 13, 60, 1) 54%
    );
    --text-gredient: linear-gradient(
      90deg,
      rgba(189, 47, 73, 1) 0%,
      rgba(119, 13, 115, 1) 34%,
      rgba(0, 35, 119, 1) 75%
    );
  }
.about{
    .about-content{
        align-items: center;
        h3{
            font-weight: 600;
            color: var(--primary-color);
            padding-bottom: 10px;
        }
        p{
          text-align: justify;
        }
        @media screen and (max-width:992px){
          flex-direction: column;
        }
    }
}