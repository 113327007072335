:root {
  --primary-color: #000d3c;
  --bg-color: #d1e6ef;
  --button-gredient: linear-gradient(
    90deg,
    rgba(20, 78, 173, 1) 5%,
    rgba(0, 13, 60, 1) 54%
  );
  --text-gredient: linear-gradient(
    90deg,
    rgba(189, 47, 73, 1) 0%,
    rgba(119, 13, 115, 1) 34%,
    rgba(0, 35, 119, 1) 75%
  );
}


.thank-wrapper {
  height:max-content;
  position: relative;
  .more-details {
    background-color: #08143c;
    color: white;
    margin: 0 auto;
    width: 75%;
    height: 7rem;
    position: absolute;
    bottom: -30px;
    border: 2px solid white;
    border-radius: 10px;
    z-index: 2;
    left: 12%;
    padding: 2rem;
    .content {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      p{
        padding-top: 0.5rem;
      }
      .form {
        display: flex;
        justify-content: space-between;
        background-color: white;
        width: 55%;
        height: 10%;
        // padding: 0.5rem 0;
        border-radius: 2px;
         Input {
          width: 80%;
          font-size: 14px;
          font-family: "Poppins", sans-serif ;
          margin: 0.5rem;
        }
        .submit {
          background: var(--button-gredient);
          color: white;
          padding: 0.5rem 1rem ;
          margin: 0.5rem;
          border-radius: 2px;
          border: none;
          outline: none;
          font-size: 12px;
          height: 2rem;
          &:hover {
            background: var(--hover-button);
          }
        }
      }
    }
  }
  // .more-details {
  //   background-color: #08143c;
  //   color: white;
  //   margin: 0 auto;
  //   width: 75%;
  //   height: 7rem;
  //   position: absolute;
  //   bottom: -30px;
  //   border: 2px solid white;
  //   border-radius: 10px;
  //   z-index: 2;
  //   left: 12%;
  //   padding: 2rem;
  //   .content {
  //     display: flex;
  //     justify-content: space-between;
  //     font-size: 20px;
  //     p{
  //       padding-top: 0.5rem;
  //     }
  //     .form {
  //       display: flex;
  //       justify-content: space-between;
  //       background-color: white;
  //       width: 55%;
  //       height: 10%;
  //       // padding: 0.5rem 0;
  //       border-radius: 2px;
  //       Input {
  //         width: 80%;
  //         font-size: 14px;
  //         font-family: "Poppins", sans-serif ;
  //       }
  //       .submit {
  //         background: var(--button-gredient);
  //         color: white;
  //         padding: 0.5rem 1rem ;
  //         margin: 0.5rem;
  //         border-radius: 2px;
  //         border: none;
  //         outline: none;
  //         font-size: 12px;
  //         height: 2rem;
  //         &:hover {
  //           background: var(--hover-button);
  //         }
  //       }
  //     }
  //   }
  // }
  @media screen and (max-width: 992px) {
    .more-details{
      padding: 1.5rem;
      height: 6rem;
       .content{
        font-size: 12px;
        .form {
          Input {
            font-size: 12px;
          }
          .submit {
            font-size: 10px;
          }
      }
    }
  }
}
  @media screen and (max-width: 448px) {
    .more-details{
      width: 90%;
      height: 5rem;
      padding: 0.5rem 1rem;
      bottom: -20px;
      margin: auto;
      left: 5%;
      .content{
        font-size: 12px;
        align-items: center;
        .form {
          display: flex;
          // flex-direction: column;
          background-color: white;
          align-items: center;
          width: 80%;
          // height: 10%;
          padding: 0.5rem 0;
          border-radius: 2px;
          Input {
            // width: 100%;
            height: 1rem;
            font-size: 12px;
            margin: 0 0.5rem;
          }
          .submit {
            background: var(--button-gredient);
            color: white;
            padding: 0 0.5rem ;
            margin: 0.2rem;
            border-radius: 5px;
            border: none;
            outline: none;
            font-size: 8px;
            height: 1.5rem;
            // width: 35%;
            &:hover {
              background: var(--hover-button);
            }
          }
      }
    }
  }
}

.contact-bottom-wrapper {
  background-color: var(--bg-color);
  padding-bottom: 4rem;
  .contact-bottom {
    padding: 2rem 0;
    .title {
      margin-bottom: 2rem;
      h2 {
        color: var(--primary-color);
        font-weight: 700;
        letter-spacing: 2px;
        margin: 0;
      }
      p {
        color: var(--primary-color);
        font-weight: 600;
        margin: 0;
        letter-spacing: 2px;
      }
    }
    .details {
      justify-content: space-around;
      padding-bottom: 1rem;

      &__content {
        p {
          font-size: 2rem;
          color: var(--primary-color);
        }
        h5 {
          font-weight: 600;
          color: var(--primary-color);
          // margin-bottom: 2rem;
        }
      }
      @media screen and (max-width: 992px) {
        flex-direction: column;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .contact-bottom{
      padding-top: 2rem;
      .title {
        margin-bottom: 0;
      }
      .details {
        padding: 0 2rem;
        &__content {
          padding-bottom: 1rem;
          h5{
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
  .thank {
    flex-direction: row;
    margin: 3% auto;
    justify-content: space-between;

    .innerPage__form {
      width: 40%; 
      height: auto;   
      .error-message {
        color: #ff0000;
        font-size: 13px;
        margin-top: 10px;       
      }
      p {
        font-size: 12px;
        font-weight: 600;
        color: var(--primary-color);
      }
      button {
        width: 100%;
        outline: none;
        border: none;
        padding: 0.5rem 1rem;
        border: 1px solid rgba(128, 128, 128, 0.253);
        border-radius: 5px;
        color: #fff;
        font-weight: 600;
        // letter-spacing: 2px;
        background: var(--button-gredient);
        &:hover {
          background: var(--hover-button);
        }
      }
      .form-control {
        background: transparent;
        border: none;
        margin: 0;
        padding: 0;
        padding-bottom: 1rem;
        label {
          display: block;
          padding: 0.5rem 0;
          color: black;
          font-weight: 600;
          font-size: 14px;
          // letter-spacing: 2px;
        }
        input {
          width: 100%;
          outline: none;
          border: none;
          padding: 0.5rem 1rem;
          border: 1px solid rgba(128, 128, 128, 0.234);
          border-radius: 5px;
          height: 32px;
        }
        textarea {
          width: 100%;
          height: 7rem;
          resize: none;
          outline: none;
          border: none;
          padding: 0.5rem 1rem;
          border: 1px solid rgba(128, 128, 128, 0.253);
          border-radius: 5px;
          // background: transparent;
        }
        .ant-select .ant-select-selector .ant-select-selection-search .ant-select-selection-search-input
        {
          border: none ;
        } 
        
       }
    }

    .quote-img{
      max-width: 100%;
      min-width: 40%;
      height: auto;
      margin-left: 5%;
    }

    .imgDiv {
      h2{
        font-weight: 600;
        background: var(--text-gredient);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        // margin-bottom: 2rem;
      }
    }
    .social-icons {
      gap: 1rem;
      margin-bottom: 2rem;
      button {
        padding: 0.5rem 1rem;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid transparent;
        outline: none;
        font-weight: 600;
        &:hover {
          background: white !important;
          border: 1px solid black;
          transition: all 0.5s ease;
          transform: scale(1.1);
        }
        &:nth-child(1) {
          background: #097ceb;
        }
        &:nth-child(2) {
          background: #1da1f2;
        }
        &:nth-child(3) {
          background: #b7081b;
        }
        &:nth-child(4) {
          background: #ea4335;
        }
      }
    }
    .back {
      button {
        width: 100% !important;
        font-weight: bold;
      }
    }

    @media screen and (max-width: 991px) {
      flex-direction: column;
      .innerPage__form{
        width: 80%;
        padding-bottom: 2rem;
        margin: 0 auto;
      }
      .quote-img{
        margin: 0 auto;
      }
    }
    
  }

}

@media screen and (max-width: 991px) {
  .thank-wrapper {
    .thank {
      width: 75%;
      .innerPage__form {
        .form-control{
          label{
            font-weight: 600;
            font-size: 12px;
          }
        }
      }
   }
}
}